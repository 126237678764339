
/* eslint-disable *///Disable for process
import axios from 'axios';
import ResourceGetter from './resourceGetter'
import { authApi } from '@/api'

class ScoreDataGetter extends ResourceGetter {
  constructor() {
    super()
    this.appData = null
  }

  // Web模式，用微信token获取曲谱资源的URL
  async getWebAppData(lessonId) {
    try {
      let data = (await authApi.getWebAppData({
        type: 'mMusic,mMusicConfig,mMusicMp3',
        lesson_id: lessonId
      })).data
      if (data.code === 0) {
        let appData = this.appData = data.data.data[0]
        let urls = this._generateAppUrl(this.appData)
        return {
          appData,
          urls
        }
      } else {
        this.appData = null
        throw data.errors[0].err_msg
      }
    } catch (err) {
      console.error(err)
    }
  }

  // 用app的token获取曲谱资源的URL
  async getAppData(lessonId) {
    try {
      let data = (await authApi.getAppData({
        type: 'mMusic,mMusicConfig,mMusicMp3',
        lesson_id: lessonId
      })).data
      if (data.code === 0) {
        let appData = this.appData = data.data.data[0]
        let urls = this._generateAppUrl(this.appData)
        return {
          appData,
          urls
        }
      } else {
        this.appData = null
        throw data.errors[0].err_msg
      }
    } catch (err) {
      console.error(err)
    }
  }

  _generateAppUrl(appData) { // 将资源url分开
    let urls = {}
    for (let i in appData.resources) {
      let res_data = appData.resources[i]
      switch (res_data.resource_name) {
        case 'musicMetaConfig':
          urls.configJsonUrl = res_data.resource_url
          break
        case 'musicMetaMidi':
          urls.midiJsonUrl = res_data.resource_url
          break
        case 'musicMetaShape':
          urls.shapeJsonUrl = res_data.resource_url
          break
        case 'musicMetaMp3':
          urls.mp3Url = res_data.resource_url
          break
        default:
          if (res_data.resource_type === 'one') {
            urls.oneUrl = res_data.resource_url
          } else {
            console.dir(res_data)
          }
      }
    }
    return urls
  }

  async getAppOperationData(configUrl, shapeUrl, midiUrl, scoreSyncUrl) {
    let configData, shapeData, midiData
    if (configUrl) {
      configUrl = configUrl.replace('http://', 'https://');
      configData = (await axios({
        method: 'GET',
        url: configUrl,
        type: 'JSON'
      })).data
    }
    if (shapeUrl) {
      shapeUrl = shapeUrl.replace('http://', 'https://');
      shapeData = (await axios({
        method: 'GET',
        url: shapeUrl,
        type: 'JSON'
      })).data
    }
    if (midiUrl) {
      midiUrl = midiUrl.replace('http://', 'https://');
      midiData = (await axios({
        method: 'GET',
        url: midiUrl,
        type: 'JSON'
      })).data
    }

    let scoreSyncData = null
    if (scoreSyncUrl) {
      scoreSyncUrl = scoreSyncUrl.replace('http://', 'https://');
      scoreSyncData = (await axios({
        method: 'GET',
        url: scoreSyncUrl,
        type: 'JSON'
      })).data
    }
    return {
      pages: shapeData ? shapeData.pages : {},
      midi: midiData ? midiData.midi : {},
      svgs: shapeData ? shapeData.svgs : {},
      scoreSyncData: scoreSyncData,
      config: configData || {},
    }
  }
}

export default new ScoreDataGetter()
