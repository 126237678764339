/* eslint-disable *///Disable for process
import axios, { AxiosRequestConfig } from 'axios';
// import store from '@/store'

function setInterceptors (axiosInstance: any) {
  // request interceptor
  axiosInstance.interceptors.request.use((req: any) => {
    let token = localStorage.getItem('token') || null
    let platform = (localStorage.getItem('platform')) as string === '0' ? 'ios' : 'android'
    let version = localStorage.getItem('version') || '7.0.0'
    if (!req.headers['token']) {
      req.headers['token'] = token
      if (req.baseURL.indexOf('lpi') > 0) req.headers['dss-token'] = token
    }
    req.headers['platform'] = platform
    req.headers['version'] = version
    return req
  }, (error: any) => {
    console.error(error)
    return Promise.reject(error)
  })

  // response interceptor
  axiosInstance.interceptors.response.use((res: any) => {
    console.log('network->interceptors');
    if (res.data.code && res.data.code !== 0 && res.data.errors && res.data.errors[0].err_no === 'invalid_token') {
      console.error('axios->登录失效，请重新登录')
    }
    return res
  }, (error: any) => {
    console.dir(error)
    if (error.code === 'ECONNABORTED') {
      console.error('网络错误，请检查网络连接刷新后重试')
    } else {
      console.error('发生了奇怪的错误')
    }
    return Promise.reject(error)
  })
}

// create axios instance
function getAxios (settings: AxiosRequestConfig | undefined, timeout = 10000) {
  const axiosInstance = axios.create(settings)
  axios.defaults.timeout = timeout
  setInterceptors(axiosInstance)
  return axiosInstance
}

export const dpi = getAxios({
  baseURL: '/dpi',
  withCredentials: true,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const ai = getAxios({
  baseURL: '/ai',
  withCredentials: true,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'SERVICEID': 3
  }
})

export const aiAdmin = getAxios({
  baseURL: '/beethoven',
  withCredentials: true,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'SERVICEID': 3
  }
})
