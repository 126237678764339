import { ai, dpi, aiAdmin } from './axios'

export const authApi = {
  // 获取测评记录
  getPlayRecord (recordId: number): any {
    return ai.get('/1.0/eval/record/' + recordId)
  },
  // 回放页获取声音
  getAudio (recordId: number): any {
    return ai.get('/1.0/eval/user_audio/' + recordId)
  },
  getWebAppData (form: IResourceParams): any {
    return dpi.get('/student_web/opn/lesson_resources', {
      params: form
    })
  },
  getAppData (form: IResourceParams): any {
    return dpi.get('/student_app/opn/lesson_resources', {
      params: form
    })
  },
  // getWebAppData (form: IResourceParams): any {
  //   return aiAdmin.get('/v1/lesson/lesson_resource', {
  //     params: form
  //   })
  // },
}
