/* eslint-disable *///Disable for process
export default class ResourceGetter {
  constructor() {
  }

  reportApiError(data) {
    if (data.errors && data.errors.length > 0) {
      let errorNo = data.errors[0].err_no;
      if (errorNo === 'invalid_token') {
      }
    }
  }

  clientApiError(code) {
    if (parseInt(code) === 1001) {
    }
  }
}
