class BtnLock {
  constructor () {
    this._lock = false
    this._timer = null
  }

  initLock (duration = 3000) {
    this._lock = true
    clearTimeout(this._timer)
    this._timer = setTimeout(() => {
      this._lock = false
    }, duration)
  }

  get lock () {
    return this._lock
  }
}

export default new BtnLock()
