import {
  MusicScore,
  MusicScoreCursor,
  MusicScoreLayoutMode
} from '@xiaoyezi/musicscore-engine'

export default {
  data: () => ({
    musicPlayEngine: null,

    showMenu: true, // 菜单栏
    phraseTitleCreated: false, // 是否显示了乐句名称

    playStartTime: 0, // 开始播放时间，时间戳

    scoreContainer: null,
    musicScore: null,
    stepOptions: [],
    cursor: null,

    section: { // 分段，分手
      aSection: null, // a段
      bSection: null, // b段
      showHandBox: false, // 分手弹框
      isAbSection: false, // 是否分段
      isFinishAb: false, // 是否完成分段
      sectionMark: '', // 文案提示
      staffId: -1, // 2：左手，1：右手，-1：双手
      scoreABSection: null, // MusicScoreAB
      sectionFields: {
        touchedNotes: [],
        start: {
          system: null,
          view: null,
          tick: null,
          event: null
        },
        end: {
          system: null,
          view: null,
          tick: null,
          event: null
        }
      }
    },

    layoutMode: null,
    designResolution: [1136, 645],
    hwRatio: 1136 / 645,
    toastLabel: {
      early: '节奏问题',
      late: '节奏问题',
      wrong: '错音',
      pause: '卡顿'
    },
    preItemSection: null,
    abTaskArr: null
  }),
  methods: {
    // 创建曲谱
    createMusicScore () {
      this.layoutMode = MusicScoreLayoutMode.PageLayout
      this.designResolution = [1136, 645]
      this.hwRatio = this.designResolution[1] / this.designResolution[0]
      this.scoreContainer = document.getElementById('g-content')
      this.scoreContainer.innerHTML = ''
      if (this.musicScore) {
        this.musicScore = null
      }
      this.phraseTitleCreated = false
      this.musicScore = new MusicScore({
        container: this.scoreContainer,
        layout_mode: this.layoutMode,
        page_space_size: 15,
        page_top_margin: 0,
        page_left_margin: 0,
        bg_color: 'rgb(255, 240, 0)',
        layout_params: {
          page: {},
          stack: {
            system: {
              position: [50, 70]
            }
          }
        }
      })
      this.musicScore.load(this.scoreData.pages, this.scoreData.svgs.svgs)
      this.musicScore.position = [0, 0]
      const height = this.scoreContainer.clientWidth * 1.4 + 'px' // 设置a4纸比例
      this.musicScore.size = [this.scoreContainer.clientWidth, height]

      this.cursor = new MusicScoreCursor(this.musicScore)
      this.cursor.color = 'rgba(248,211,71,0.4)'
      this.cursor._horizontalFollow = false
      this.cursor._topMargin = 50
      this.cursor.borderRx = 4
      this.cursor.borderRy = 4
      this.cursor.visible = false
    }
  }
}
